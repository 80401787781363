<template>
    <footer>
      <div class="container">
        <div class="footer-content">
          <p>Violence Works LLC 2023</p>
        </div>
      </div>
    </footer>
  </template>
  
  <style>
    footer {
      background-color: #6c6c6c;
      color: #fff;
      padding: 2em 0;
    }
  
    .container {
      max-width: 1024px;
      margin: 0 auto;
      text-align: center;
    }
  
    .footer-content a {
      color: #fff;
      text-decoration: none;
      margin-right: 1em;
    }
  </style>
  